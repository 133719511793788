import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import EditClientCell from 'src/components/SuperAdmin/Client/EditClientCell'

type ClientPageProps = {
  id: number
}

const EditClientPage = ({ id }: ClientPageProps) => {
  return (
    <>
      <Metadata
        title="Client Management - Edit Client"
        description="Client Management - Edit Client"
      />
      <PageHeader title="Client Management - Edit Client" />

      <EditClientCell id={id} />
    </>
  )
}

export default EditClientPage
